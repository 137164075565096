.badge {
  @apply rounded-full py-[3px] text-xs font-semibold;

  &--filled {
    @apply bg-primary-100 px-1.5 text-primary-950 ring-1 ring-inset ring-primary-600;
  }

  &--outlined {
    @apply border border-gray-500 bg-transparent px-1 leading-none text-gray-600;
  }

  &--xs {
    @apply text-[10px];
  }
}

.pill {
  @apply inline-flex items-center rounded-md  px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset;

  &-xs {
    @apply py-[2px] px-1 text-[10px] leading-3 rounded font-semibold;
  }

  &-primary {
    @apply bg-primary-50 text-primary-700 ring-primary-600/20;
  }

  &-white {
    @apply bg-white text-black ring-washed-gray-darker;
  }

  &-success {
    @apply bg-emerald-50 text-emerald-700 ring-emerald-600/40;
  }
  &-danger {
    @apply bg-red-50 text-red-700 ring-red-600/20;
  }
  &-warning {
    @apply bg-orange-50 text-orange-700 ring-orange-600/20;
  }

  &-info {
    @apply bg-blue-50 text-blue-700 ring-blue-600/20;
  }

  &-muted {
    @apply bg-slate-50 text-slate-700 ring-slate-600/20;
  }
}
