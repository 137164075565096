/* Icon Type */

[data-icon-type='list'] {
  @apply text-blue-500;
}

[data-icon-type='json'] {
  @apply text-fuchsia-600;
}

[data-icon-type='boolean'] {
  @apply text-green-500;
}

[data-icon-type='datetime'] {
  @apply text-teal-500;
}

[data-icon-type='integer'],
[data-icon-type='number'] {
  @apply text-violet-500;
}

[data-icon-type='string'] {
  @apply text-orange-500;
}

[data-icon-type='prompt'] {
  @apply text-blue-500;
}

/* Selector Type */

[data-selector-type='list'] {
  @apply hover:text-blue-500;
}

[data-selector-type='json'] {
  @apply hover:text-yellow-500;
}

[data-selector-type='datetime'] {
  @apply hover:text-teal-500;
}

[data-selector-type='boolean'] {
  @apply hover:text-green-500;
}

[data-selector-type='number'],
[data-selector-type='integer'] {
  @apply hover:text-violet-500;
}

[data-selector-type='string'] {
  @apply hover:text-orange-500;
}

[data-selector-type='prompt'] {
  @apply hover:text-blue-500;
}

/* Selector Type Selected */

[data-selector-type='*'].selected {
  @apply bg-transparent;
}

[data-selector-type='list'].selected {
  @apply text-blue-500;
}

[data-selector-type='json'].selected {
  @apply text-yellow-500;
}

[data-selector-type='boolean'].selected {
  @apply text-green-500;
}

[data-selector-type='datetime'].selected {
  @apply text-teal-500;
}

[data-selector-type='number'].selected {
  @apply text-violet-500;
}

[data-selector-type='string'].selected {
  @apply text-orange-500;
}

[data-selector-type='prompt'].selected {
  @apply text-blue-500;
}

// Creator background

[data-creator-type] {
  @apply text-black;
}

[data-creator-type='list'] {
  @apply bg-blue-200;
}

[data-creator-type='boolean'] {
  @apply bg-green-200;
}

[data-creator-type='datetime'] {
  @apply bg-teal-200;
}

[data-creator-type='number'],
[data-creator-type='integer'] {
  @apply bg-violet-200;
}

[data-creator-type='string'] {
  @apply bg-orange-200;
}

[data-creator-type='prompt'] {
  @apply bg-blue-200;
}

/* CELLS */
.studio-cell {
  @apply relative flex items-center gap-x-1 truncate  border-b border-washed-gray-darker px-2 text-xs text-black/80 transition-opacity after:absolute after:right-0 after:h-full after:w-[1px] after:bg-washed-gray-darker;

  &.header {
    @apply border-b-0 font-medium text-black/80;
  }

  &.picker {
    @apply justify-center px-0;
  }

  &.with-value {
    @apply bg-gray-50 px-0;
  }

  &.numeral {
    @apply justify-center bg-washed-gray-light/70 p-0 text-black/40;
  }

  &.picker,
  &.tail,
  &.numeral {
    @apply after:bg-transparent;
  }

  &.data,
  &.with-value,
  &.numeral {
    @apply w-full border-b border-washed-gray-darker;
  }

  &.header.numeral {
    @apply border-b-0;
  }

  &.data {
    @apply px-0;
  }
}
