// EDITOR
.nz-editor {
  @apply mx-auto my-11 flex max-w-xl flex-col gap-y-6;

  &__back {
    @apply flex items-center gap-x-1 self-start rounded bg-black/5 px-2 py-0.5 text-[13px] font-semibold text-black/60 hover:bg-black/10 hover:text-black dark:bg-dark-700 dark:text-dark-50 dark:hover:bg-dark-600;
  }
}

// WRAPPER
.nz-form-wrapper {
  @apply rounded bg-white p-6 ring-1 ring-washed-gray-darker;
}

// HEADER
.nz-form-header {
  @apply flex items-center justify-between;

  &__title {
    @apply max-w-[30rem] truncate text-xl font-semibold;
  }

  &__menu {
    @apply flex-center h-6 w-6 cursor-pointer rounded-full dark:hover:bg-dark-700;

    ox-icon {
      @apply transition-transform group-hover:scale-95;
    }
  }
}

// ERROR
.nz-form-error {
  @apply mt-2 flex items-center text-xs text-red-500;

  &__tooltip {
    @apply ml-1 cursor-help text-red-600;
  }
}

.nz-character-count {
  @apply pointer-events-none absolute bottom-0 right-0 flex items-center justify-end pr-3 text-[10px] text-gray-500 dark:text-dark-100;
}

// FORM
.nz-form {
  @apply mt-4 flex flex-col gap-y-4;
}

// PANEL
.nz-form-panel {
  @apply min-h-[5rem] p-5 rounded bg-white ring-1 ring-black/5;
}

// LABEL
.ant-form-item-label {
  @apply mb-0 block text-left font-medium  dark:text-dark-50;

  & > label {
    @apply text-xs font-semibold text-black/70;
  }
}

// INPUT
.nz-input-icon {
  @apply absolute inset-y-0 right-0 flex items-center justify-end;
}

.ant-input,
.ant-input-number,
.ant-picker input {
  @apply outline-0;
}

.ant-input-number-group-addon {
  @apply text-xs font-medium;
}

.ant-input-affix-wrapper .ant-input {
  min-height: auto;
}

.ant-btn-block {
  @apply flex h-[2.2rem] items-center justify-center;

  ox-icon {
    @apply ml-1;
  }
}

input.ant-input + .nz-character-count {
  @apply inset-y-0;
}

// SELECT
.ant-dropdown-menu-item-selected,
.ant-dropdown:not(.basic-dropdown-menu) .ant-dropdown-menu-item-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply relative bg-transparent font-semibold before:absolute before:left-0 before:top-0 before:h-full before:w-[3px] before:bg-primary-400;
}

.ant-select-selection-item-remove {
  @apply ml-1;

  color: black !important;
}

.ant-dropdown-menu-item-selected {
  @apply bg-transparent;
}

.ant-dropdown-menu-item-disabled {
  @apply pointer-events-none;
}

.anticon-check {
  @apply hidden;
}

.ant-select-arrow {
  right: 0.5rem;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-select-item {
  @apply text-xs font-medium;
}

.ant-dropdown-menu-submenu-expand-icon {
  @apply top-0.5;
}

.nz-cdk-option,
.ant-input,
.ant-input-number,
.ant-picker-input input,
.ant-select-item,
.ant-select-selector,
.ant-dropdown-menu-item,
.ant-select-selection-item,
.ant-picker input,
.ant-input-affix-wrapper .ant-input {
  @apply flex items-center text-xs font-medium;

  line-height: unset;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  &:hover {
    color: #ff4d4f;
    @apply bg-gray-200;
  }

  &.ant-dropdown-menu-item-disabled {
    @apply opacity-60;
  }
}

nz-select.nz-cdk-calculate nz-select-item {
  @apply justify-end;
}

.nz-select-option {
  @apply flex items-center gap-x-1;
}

.ant-form-item-explain {
  @apply mt-0.5 text-xs;
}

.ant-select-selection-item {
  @apply flex items-center;
}

.clear-submenu-icon {
  .ant-dropdown-menu-submenu-title {
    @apply pr-3;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    @apply hidden;
  }
}

// SELECT MULTIPLE
.ant-select-multiple {
  .ant-select-selection-item {
    @apply rounded-full px-2 font-medium;
  }

  .ant-select-selection-item-content {
    @apply mr-0;
  }

  .ant-select-selection-item {
    @apply max-h-[20px] border-0 bg-blue-200 py-0.5 pl-2 pr-1.5;
  }

  .ant-select-item-option {
    @apply mb-1 px-2;
  }

  .ant-select-selector {
    cursor: pointer !important;
  }
}

.ant-select-clear {
  top: 0.85rem;
}

.logs-dropdown {
  @apply w-[150px] text-xs;

  .ant-select-clear {
    @apply top-[0.6rem];
  }
}

// SIMULATOR SELECT DROPDOWN
.simulator-select {
  .ant-select-selector {
    @apply pl-3;
  }
}

.simulator-select-dropdown {
  .ant-select-item {
    @apply mb-1;
    min-height: unset;
  }
}

// DROPDOWN
.ant-dropdown-menu-item-disabled,
.prompt-menu-item-disabled {
  @apply text-gray-500;
}

.prompt-menu-item-disabled {
  @apply cursor-not-allowed;
}

// TEXTAREA
textarea.ant-input {
  @apply resize-none py-2 text-xs;

  // line-height: unset;

  & + .nz-character-count {
    @apply inset-x-0;
  }
}

// HIGHLIGHT
.nz-form-highlight > label {
  @apply cursor-pointer text-primary-600;

  &::after {
    display: none;
  }
}

// BUTTON
.ant-btn {
  @apply inline-flex h-8 items-center rounded px-2.5 text-xs font-semibold;
}

.ant-btn-sm {
  @apply h-6 px-1.5 font-medium;
}

.ant-btn-lg {
  @apply px-3 text-sm;
}

.ant-btn-link {
  @apply text-primary-700;
}

.ant-btn-block {
  @apply flex h-[2.2rem] items-center justify-center;

  ox-icon {
    @apply ml-1;
  }
}

.ant-btn-primary {
  @apply border-0 bg-primary-600 text-white hover:bg-primary-700;
}

.ant-btn.ant-btn-primary[disabled] {
  @apply bg-primary-600 text-white opacity-50;
}

.ant-btn-group {
  .ant-btn:hover {
    @apply z-auto;
  }

  .ant-btn-primary:first-child:not(:last-child) {
    @apply border-r border-r-primary-700/50;
  }

  .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    @apply border-l border-l-primary-700/50;
  }
}

.nz-form-button {
  @apply mt-6 flex justify-end gap-x-2 self-end;
}

// SLIDER
.ant-slider-rail {
  @apply bg-zinc-200;
}

// TOOLTIP
.ant-tooltip {
  @apply text-xs font-medium;
  z-index: 1200 !important;
}

.ant-tooltip-inner {
  min-height: unset !important;
}

.ant-tooltip-arrow-content,
.ant-tooltip-inner {
  @apply bg-black;
}

// SWITCH
.ant-switch-checked {
  @apply bg-primary-600;
}

.ant-switch,
.ant-switch:focus {
  @apply shadow-none outline-0;
}

.ant-switch .ant-click-animating-node {
  @apply hidden;
}

// DIALOG
.nz-clone-dialog .cdk-overlay-pane {
  z-index: 1100 !important;
}

// PICKER
.ant-picker,
.ant-picker-dropdown,
.ant-picker-panel-container {
  @apply min-h-[32px] rounded;
}

.ox-datetime-picker {
  .ant-picker-cell,
  .ant-picker-time-panel-cell-inner {
    @apply font-medium;
  }

  .ant-picker-time-panel {
    @apply hidden;
  }

  &--time .ant-picker-time-panel {
    @apply block;
  }

  .ant-picker-body,
  .ant-picker-time-panel-column {
    @apply text-xs;
  }

  .ant-picker-ranges {
    @apply hidden;
  }

  .ant-picker-time .ant-picker-footer,
  .ant-picker-footer-extra:not(:last-child) {
    @apply border-b-0;
  }

  .clear {
    padding: 0 0.4rem !important;
    height: 1.3rem !important;
    font-size: 0.7rem !important;
  }
}

.matrix-datetime-picker {
  .ant-picker {
    @apply min-h-[30px] border-0 bg-transparent p-0 shadow-none;
  }

  .ant-picker-suffix {
    display: none !important;
  }

  .ant-picker-input > input {
    @apply cursor-pointer text-xs;

    // TODO: Normalize all ng zorro input colors to conform with system colors
    color: rgb(11, 11, 11);
  }
}

// COLLAPSE
.ox-collapse-no-padding {
  .ant-collapse-header,
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  @apply flex items-center py-2 px-4;
}

.ant-collapse-borderless .ant-collapse-content-active {
  @apply shadow-inner;
}

.nz-panel-scrollable .ant-collapse-content-box {
  @apply max-h-[14.5rem] overflow-y-auto;
}

// AVATAR
.ant-avatar {
  @apply flex items-center justify-center bg-primary-300;
}

.ant-avatar-string {
  @apply font-medium;

  transform: scale(1) translateX(-50%);
}

.ant-avatar-sm {
  .ant-avatar-string {
    @apply text-xs;
  }
}

.ant-avatar-lg {
  @apply h-full w-full;

  .ant-avatar-string {
    @apply text-3xl;
  }
}

.nz-log-avatar img {
  @apply object-contain;
}

// PROGRESS
.ant-progress {
  @apply cursor-default text-[10px];
}

.ant-progress-circle .ant-progress-text {
  @apply font-medium;

  color: rgb(11 11 11 / 0.8) !important;
}

// SPINNER
.ant-spin-dot-item {
  @apply bg-primary-600;
}

// SEGMENTED
.ant-segmented-item-label {
  @apply text-[0.75rem] font-medium capitalize;
}

// MODAL
.ant-modal-title {
  @apply text-sm;
}

.ant-modal-footer {
  @apply px-6 py-4;
}

.ant-full-screen-modal {
  @apply p-0 h-full w-full max-w-full top-0 overflow-hidden;

  .ant-modal-content {
    @apply h-full w-full max-w-full flex flex-col;

    .ant-modal-body {
      @apply flex grow overflow-auto;
    }
  }
}

// MESSAGE
.ant-message-custom-content {
  @apply flex items-center text-[13px] font-medium;
}

// Input Picker
nz-date-picker input {
  @apply tabular-nums;
}

// CHECKBOX
.ant-checkbox {
  top: 0.1em;
}

.ant-checkbox + span {
  @apply relative top-[-0.05rem];
}

// SKELETON
.ant-skeleton-title {
  margin-top: 0 !important;
}

.ant-skeleton-input,
.ant-skeleton-button {
  @apply rounded;

  width: 100% !important;
  height: 100% !important;
}

// MENU
.ant-menu-title-content {
  @apply w-full;
}

nz-collapse-panel.no-padding-box {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.ant-input-textarea-show-count {
  @apply text-xs;
}

.ant-breadcrumb.custom {
  .ant-breadcrumb-separator {
    @apply m-0;
  }
}

.ant-breadcrumb a {
  @apply text-xs hover:underline underline-offset-2;
}

.ant-breadcrumb-link {
  @apply h-full;
}

ox-string-quick-calculator,
ox-number-quick-calculator,
ox-boolean-quick-calculator ox-apply-all-selector {
  .ant-select-clear {
    @apply bg-washed-gray;
  }
}

// LAYOUT
.ant-layout-sider {
  @apply overflow-hidden;
}

.ant-layout-sider-trigger {
  @apply hidden;
}
