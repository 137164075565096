.ant-switch:not(.ant-switch-checked) {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    ),
    linear-gradient(to right, #fff, #fff) !important;
}

.ant-switch-checked {
  @apply bg-primary-default;
}
