/* deprecated */
.ox-list {
  @apply w-full select-none py-0.5;
}
/* deprecated */
.ox-list-separator {
  @apply my-1 h-[1px] w-full border-neutral-100 bg-neutral-100 dark:bg-dark-50;
}

/* deprecated */
.ox-list-item {
  @apply relative mt-0.5 flex items-center  rounded-sm py-1.5 px-2 text-xs tracking-wide transition-colors hover:bg-neutral-100 dark:text-white/70 dark:hover:bg-black/40 dark:hover:text-white;

  &.active,
  &.selected {
    @apply before:absolute before:left-0 before:h-full before:w-[3px] before:bg-blue-200;
  }

  &.disabled {
    @apply pointer-events-none opacity-50;
  }
}
