.dropdown {
  @apply mt-0.5 flex min-w-[160px] flex-col gap-y-0.5 overflow-hidden rounded bg-white px-0 py-1 text-[13px] shadow-[0_4px_16px] shadow-black/5 ring-1 ring-inset ring-gray-300 focus-visible:bg-red-50 dark:bg-dark-800 dark:text-dark-50 dark:shadow-soft-dark dark:ring-dark-700;

  &__wrapper {
    @apply flex w-full flex-col;
  }

  &__title {
    @apply flex h-6 items-center px-2 text-xs text-gray-500;
  }
}

.dropdown-item {
  @apply relative mt-0.5 flex cursor-pointer items-center rounded-sm px-2 py-1.5 text-xs font-medium tracking-wide text-black transition-colors hover:bg-neutral-100 dark:text-white dark:text-white/70 dark:hover:bg-black/40 dark:hover:text-white;

  &.active,
  &.selected {
    @apply before:absolute before:left-0 before:h-full before:w-[3px] before:bg-blue-200;
  }

  &.disabled {
    @apply pointer-events-none opacity-50;
  }

  &__option {
    @apply text-default;
  }
}

.dropdown-search {
  @apply mb-1 flex flex-col shadow;

  &__wrapper {
    @apply flex w-full flex-wrap bg-gray-100 px-3 py-2 ring-1 ring-inset ring-gray-300 dark:bg-dark-600 dark:text-dark-50 dark:shadow-dark-600 dark:ring-dark-700;
  }

  &__button {
    @apply ml-1 focus:outline-none;
  }
}

.dropdown-keyword {
  @apply w-full flex-shrink flex-grow basis-[60px] text-xs;

  &__input {
    @apply w-full border-0 bg-transparent p-0 text-xs leading-5 text-black focus:outline-none focus:ring-0 dark:text-white;
  }
}

.keyword-create {
  @apply flex items-center px-2 py-1;

  &__button {
    @apply mt-0.5 cursor-pointer text-[10px] font-semibold uppercase leading-4 tracking-wide hover:opacity-80 dark:text-white;
  }
}

.keyword-option {
  @apply mr-1.5 flex flex-grow overflow-hidden;

  &__body {
    @apply flex items-start overflow-hidden rounded-2xl px-2 py-0.5 text-default text-gray-900;
  }

  &__label {
    @apply truncate text-xs leading-5;
  }
}
