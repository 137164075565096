html.dark {
  .orquesta-toast-container {
    @apply bg-dark-500 text-[13px] font-medium text-white;
  }
  .hot-toast-close-btn {
    @apply text-white;
  }
}

.orquesta-toast-container {
  @apply text-[13px] font-medium max-w-md;
}

// Temporary workaround for toasts not dismissing in Angular 15
@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 130px, -1px) scale(0.5);
  }
}

@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -130px, -1px) scale(0.5);
  }
}
