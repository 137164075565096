// DASHBOARD
.dashboard {
  @apply mx-auto my-12 grid max-w-8xl px-8;
}

// HEADER
.dashboard-header {
  @apply mb-8 flex items-center justify-between;

  &__time-status {
    @apply flex items-center text-xs font-medium text-gray-600;
  }
}

// DASHBOARD PANEL
.dashboard-panel {
  @apply relative rounded bg-white p-4 shadow-card;
}

.dashboard-title {
  @apply mb-4 text-xs font-medium text-gray-700;
}

// DASHBOARD PILL
.dashboard-pills {
  @apply flex items-center gap-x-2;

  &__pill {
    @apply cursor-pointer rounded-2xl border border-primary-500 px-2 py-0.5 text-xs font-medium text-primary-500;

    &--active {
      @apply bg-primary-500 text-white;
    }
  }
}

// DASHBOARD EMPTY
.dashboard-empty {
  @apply flex items-center justify-center text-xs font-medium text-gray-500;

  height: calc(100% - 4rem);
}
