.breadcrumb {
  @apply text-xs shrink-0 transition-colors pr-1 pl-1.5 py-1 rounded hover:bg-washed-gray cursor-pointer hover:text-black flex items-center gap-x-0.5;

  &__plain {
    @apply hover:bg-transparent cursor-text;
  }

  &__title {
    @apply truncate;
  }
}
