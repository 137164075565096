// SIDEBAR
.sidebar {
  @apply flex h-full min-w-[14rem] flex-col justify-between p-3;
}

// LOGO
.sidebar-logo {
  @apply mb-6;

  &__logo {
    @apply inline-flex cursor-pointer rounded-full bg-gradient-to-br from-primary-500 to-primary-600 p-1.5 text-primary-50;
  }

  &__title {
    @apply text-sm font-semibold uppercase text-primary-600;
  }
}

// HEADER
.sidebar-header {
  @apply flex items-center justify-between;

  &__logout {
    @apply cursor-pointer rounded-full p-[0.2rem] text-gray-700 hover:bg-gray-200;
  }
}

// MENU
.sidebar-menu {
  @apply flex flex-col gap-y-6;

  &__title {
    @apply text-[0.6rem] font-medium uppercase leading-3 tracking-wider text-gray-600;
  }

  &__items {
    @apply flex flex-col gap-y-0.5;
  }

  &__arrow-right,
  &__arrow-down {
    @apply transform transition duration-300 ease-in-out;
    transform-origin: center;
  }

  &__arrow-right {
    transform: rotate(0deg);
  }

  &__arrow-down {
    transform: rotate(90deg);
  }
}

// ITEM
.sidebar-item {
  @apply cursor-pointer rounded  uppercase py-1.5 text-[0.6rem] leading-3 tracking-wider font-medium duration-300 ease-in-out;

  &:not(&--single) {
    @apply font-semibold text-gray-600;
  }

  &--single {
    @apply font-semibold text-gray-800/90;
  }

  &--active {
    @apply bg-primary-600 font-semibold text-primary-50 hover:text-primary-50;
  }

  &__menu {
    @apply flex items-center gap-x-2 pl-2 py-1.5 rounded;

    &:not(&__active) {
      @apply hover:bg-gray-200;
    }

    &__active {
      @apply bg-primary-600 font-semibold text-primary-50 hover:text-primary-50;
    }
  }

  &__children {
    @apply overflow-hidden flex flex-col;

    &_item {
      @apply cursor-pointer rounded text-xs font-medium text-gray-800/90 duration-300 ease-in-out;
    }
  }
}

// DOCS
.docs {
  &__menu {
    @apply flex py-1.5 items-center justify-between;
  }
}

// Animation to rotate 20deg to the right
@keyframes rotate-20deg {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
