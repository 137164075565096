.wrapper {
  @apply flex flex-1;

  &__router {
    @apply h-full w-full pl-12 pr-8;
  }
}

.wrapper-sidebar {
  @apply h-full w-[260px] flex-shrink-0 border-r border-gray-200 bg-gray-100 dark:border-dark-800 dark:bg-dark-800;

  &__content {
    @apply mx-4 mt-6 flex flex-col gap-y-0.5;
  }

  &__tab {
    @apply flex h-7 items-center rounded px-3 text-xs font-medium hover:bg-gray-200;

    &:not(&--active) {
      @apply dark:hover:bg-dark-600;
    }

    &--active {
      @apply bg-primary-200 text-primary-800 hover:bg-primary-200;
    }

    &--inactive {
      @apply pointer-events-none cursor-not-allowed select-none text-gray-500 dark:text-dark-500;
    }
  }
}

.wrapper-form {
  @apply mt-8 mb-5;

  &__form {
    @apply flex flex-col gap-4;
  }

  &__content {
    @apply mt-10 max-w-xs;
  }

  &__buttons {
    @apply flex items-center justify-end;
  }

  &__label {
    @apply text-default font-medium;
  }
}

.wrapper-header {
  @apply flex items-center justify-between;

  &__title {
    @apply text-2xl font-semibold;
  }

  &__back {
    @apply flex-center h-6 w-6 flex-shrink-0 rounded-full bg-gray-100 hover:bg-gray-200;
  }

  &__heading {
    @apply flex items-center gap-x-3;
  }

  &__menu {
    @apply flex-center h-6 w-6 cursor-pointer rounded-full;

    ox-icon {
      @apply transition-transform group-hover:scale-95;
    }
  }
}

.wrapper-alert {
  @apply mb-3 flex items-start rounded bg-yellow-100 py-2.5 px-4 text-black;

  &__counter {
    @apply ml-2 block text-default;
  }

  &__link {
    @apply cursor-pointer font-bold underline;
  }

  ox-icon {
    @apply mt-0.5;
  }
}

.wrapper-grid {
  @apply grid gap-4;
}

.wrapper-grid-item {
  @apply flex flex-col justify-start gap-y-2 rounded-sm p-4 text-center;

  &__length {
    @apply text-lg font-medium;
  }

  &__title {
    @apply text-xs;
  }
}
