.orq-tabs {
  @apply h-full flex flex-col;

  nz-tabset {
    @apply grow;
    .ant-tabs-nav {
      @apply px-4;
      margin-bottom: 0 !important;
    }

    .ant-tabs-tab {
      @apply text-xs font-medium h-full;
    }

    .ant-tabs-content {
      @apply h-full;
    }
  }
}

.product-trace-tabs {
  @apply h-full flex flex-col;

  nz-tabset {
    @apply grow;
    .ant-tabs-nav {
      @apply px-4;
      margin-bottom: 0 !important;
    }

    .ant-tabs-tab {
      @apply text-xs font-medium h-full;
    }

    .ant-tabs-content {
      @apply h-full;
    }
  }
}
