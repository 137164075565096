.tippy-box[data-theme~='orquesta'] {
  @apply rounded-sm bg-white text-xs   shadow-soft before:fixed before:inset-0 dark:bg-dark-500   dark:shadow-soft-dark;

  .tippy-content {
    @apply p-0;
  }
}

.tippy-tooltip .tippy-content {
  @apply text-white;
}

.w-full {
  .tippy-box {
    max-width: 100% !important;
  }
}

.tippy-box[data-theme~='tooltip'] {
  @apply py-0 text-xs font-medium;
}
