/*
  TODO: Remove when migration from tippy to cdkMenu is implemented - https://orquesta.atlassian.net/browse/ORQ-1856
 */
.ox-menu {
  @apply mt-0.5 flex min-w-[160px] flex-col gap-y-0.5 overflow-hidden rounded bg-white p-1 text-[13px] shadow-[0_4px_16px] shadow-black/5 ring-1 ring-inset ring-gray-300 focus-visible:bg-red-50 dark:bg-dark-800  dark:text-dark-50 dark:shadow-soft-dark dark:ring-dark-700;
}

.ox-menu.no-shadow {
  @apply shadow-none;
}
.ox-menu.scrollable {
  @apply max-h-[20rem] overflow-y-auto;
}

.ox-menu-item {
  @apply relative  flex min-h-[2rem] cursor-pointer items-center truncate rounded px-3 py-1 text-left  text-xs font-medium leading-5 text-black/70 transition-colors hover:bg-gray-200 hover:text-black dark:text-dark-100 dark:hover:bg-dark-600 dark:hover:text-white;

  &.active {
    @apply text-blue-500 dark:text-white;

    &::before {
      @apply absolute left-0 h-full border-l-2 border-blue-500 text-white dark:border-white;
      content: '';
    }
  }

  &.disabled,
  &[aria-disabled='true'] {
    // with pointer events none, any cursor event and style will be ignored
    @apply pointer-events-none opacity-50;
  }

  &.selected {
    @apply bg-primary-100 text-primary-700 dark:bg-dark-600 dark:text-dark-50;
  }

  &.danger {
    @apply text-red-500;
  }
}

.ox-menu-separator {
  @apply my-1 h-[1px] w-full  bg-gray-100 dark:bg-dark-700;
}

.ant-dropdown-menu {
  @apply min-w-[160px];
}
