$queued-experiment: theme('colors.orange.500');
$running-experiment: theme('colors.blue.700');
$succeeded-experiment: theme('colors.emerald.700');
$failed-experiment: theme('colors.red.600');

.badge-experiment {
  @apply px-2 py-0.5 text-xs inline-flex items-center gap-x-2 font-medium rounded-full;

  &[data-experiment='queued'] {
    @apply bg-orange-100 ring-1 ring-inset ring-orange-100/10;

    color: $queued-experiment;
  }

  &[data-experiment='running'] {
    @apply bg-blue-100/50 ring-1 ring-blue-200 text-blue-800;
  }

  &[data-experiment='completed'] {
    @apply bg-emerald-100/50 ring-1 ring-emerald-200 text-emerald-800;
  }

  &[data-experiment='cancelled'] {
    @apply bg-red-100/50 ring-1 ring-red-200 text-red-800;
  }

  &[data-experiment='draft'] {
    @apply bg-washed-gray-light ring-1 ring-washed-gray text-black;
  }
}
