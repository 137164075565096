.image-container {
  @apply relative rounded overflow-hidden;

  &::before {
    @apply absolute top-0 left-0 w-full h-full opacity-0 bg-black bg-opacity-50 transition-all;

    content: '';
    z-index: -1;
  }

  &:hover {
    &::before {
      @apply opacity-100 z-10;
    }

    .image-container__controls {
      @apply opacity-100 delay-75;
    }
  }

  &__controls {
    @apply absolute flex items-center justify-center z-50 top-0 text-white opacity-0 gap-x-1.5 w-full h-full transition-all;

    ox-icon {
      @apply cursor-pointer;
    }

    ox-icon:hover {
      @apply scale-110;
    }
  }
}
