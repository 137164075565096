.parameter {
  &__title {
    @apply capitalize font-medium text-black;
  }

  &__description {
    @apply text-gray-700;
  }

  &__default {
    @apply italic text-[11px] mt-1 font-medium text-gray-700;
  }
}

.parameter-select {
  @apply grid min-w-[15rem];

  &--multi-column {
    @apply grid-cols-[10rem,minmax(10rem,1fr)] min-w-[20rem] p-4;
  }

  &--single-column {
    @apply grid-cols-1 px-4 pt-2 pb-4;
  }

  &__overview {
    @apply flex flex-col gap-y-1.5 pr-4 border-r border-gray-200;
  }
}

.parameter-list {
  @apply flex flex-col w-full;

  &__wrapper {
    @apply overflow-auto w-full max-h-[15rem];
  }

  &__options {
    @apply flex flex-col gap-y-0.5;
  }
}

.select-item {
  @apply flex items-center justify-between w-full;

  &__default-badge {
    @apply bg-gray-300 rounded px-1 ring-1 ring-gray-500;
  }
}

.parameter-default {
  @apply flex flex-col w-[15rem] p-4;

  &__wrapper {
    @apply flex flex-col gap-y-2 w-full;
  }

  &__header {
    @apply flex items-center justify-between;
  }
}
