$dark: theme('colors.dark.default');

.skeleton-box {
  @apply relative inline-block overflow-hidden;
  background-color: #323236;

  &::after {
    @apply absolute top-0 right-0 bottom-0 left-0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(rgb(255 255 255 / 0.7), 0) 0%,
      rgba(rgb(255 255 255 / 0.7), 0.2) 100%
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton-loader {
  width: 100%;
  height: 0;
  overflow: hidden;
}
.animated-line {
  margin-top: 10px;
}
.animated-line-inner {
  border-radius: 4px;
  height: 15px;
  background: -webkit-linear-gradient(
    57deg,
    rgba(240, 244, 249, 0.2) 0,
    rgba(232, 205, 226, 100) 25%,
    rgba(229, 152, 159, 100) 50%,
    rgba(232, 205, 226, 100) 75%,
    rgba(240, 244, 249, 0.2) 100%
  );
  background: linear-gradient(
    57deg,
    rgba(240, 244, 249, 0.2) 0,
    rgb(233, 230, 245) 25%,
    rgb(255, 201, 234) 50%,
    rgb(233, 230, 245) 75%,
    rgba(240, 244, 249, 0.2) 100%
  );
  background-position: 0 0;
  background-size: 200% 100%;
  -webkit-animation: gradient-animation 2.15s linear 0s infinite;
  animation: gradient-animation 2.15s linear 0s infinite;
}
.animated-line {
  width: 100%;
}
.animated-line:first-child > div {
  animation-delay: 0s;
}
.animated-line:nth-child(2) > div {
  animation-delay: 0.5s;
}
.animated-line:nth-child(3) > div {
  animation-delay: 1s;
  width: 66%;
}

@keyframes gradient-animation {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: -200% 0;
  }
}
