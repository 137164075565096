.controls-container {
  @apply flex flex-col mt-1.5 text-xs bg-white shadow-sm rounded-sm border border-gray-200;
}

.controls-pill {
  @apply flex items-center text-[11px] cursor-pointer border border-washed-gray-darker rounded overflow-hidden hover:border-primary-300 hover:text-primary-600 ;

  &--active {
    @apply border-primary-300 text-primary-600;
  }

  &--warning {
    @apply border-orange-400 text-orange-500;

    .controls-pill__title {
      @apply font-medium;
    }
  }

  &__title, .control-pill-title {
    @apply py-1 px-1.5 capitalize border-r border-washed-gray-darker group-hover:border-primary-300;
  }

  &__value {
    @apply font-bold px-1.5 flex items-center gap-x-1;
  }
}
