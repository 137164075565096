// TODO: Rename class to "table-menu" after addressing technical debt in ORQ-3618.
// This change supports uniform use of Tippy.js for dropdowns and context menus across all tables, replacing cdkMenu.
// https://orquesta.atlassian.net/browse/ORQ-3618.
.table-cdk-menu {
  @apply relative m-0 min-w-[160px] origin-top list-none rounded bg-white bg-clip-padding px-0 py-1 text-left shadow-lg outline-0;

  .cdk-menu-item {
    @apply flex w-full h-[28.85px] cursor-pointer items-center px-3 py-[5px] text-xs font-medium outline-0;

    line-height: unset !important;
    outline: none !important;
    color: #000000b3;

    &:hover {
      background-color: #f5f5f5;
    }

    &[aria-disabled='true'],
    &[disabled] {
      @apply cursor-not-allowed opacity-50;
    }
  }

  .cdk-menu-danger {
    @apply text-red-600;
  }

  hr {
    @apply mx-0 my-1;
  }
}
