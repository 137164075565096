.var-decorator,
.event-decorator,
.knowledge-var-decorator {
  @apply font-code text-xs px-0.5 font-semibold rounded border border-l-[3px] rounded-l  border-washed-gray-darker;
}

.var-decorator {
  @apply text-emerald-900 border-l-emerald-700;

  &::before {
    content: '\1D4B3';
    @apply text-emerald-700 px-0.5  font-semibold;
  }
}

.knowledge-var-decorator {
  @apply text-amber-900 border-l-amber-700;

  &::before {
    content: '\1D4B3';
    @apply text-amber-700 px-0.5  font-semibold;
  }
}

.event-decorator {
  @apply text-primary-900 border-l-primary-700;

  &::before {
    content: '\1D4B3';
    @apply text-primary-700 px-0.5  font-semibold;
  }
}

.cm-editor {
  @apply h-full;

  font-feature-settings: 'tnum';
  font-family: inherit;
}

.cm-content {
  @apply font-sans leading-6 selection:text-inherit;
  padding: 0 !important;
  min-height: 24px !important;

  &[data-language] {
    @apply font-code font-medium;
  }
}

.ox-codemirror.code .cm-content {
  @apply font-code text-xs font-semibold leading-5 selection:text-inherit;
}

.ox-codemirror.input-mode .cm-scroller {
  @apply overflow-hidden;
}

.ox-codemirror {
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray-700;
  }
}

.small {
  .cm-content {
    @apply text-xs leading-5;
  }

  .var-decorator,
  .event-decorator {
    @apply font-semibold;
  }
}

.medium {
  .cm-content {
    @apply text-[13px] font-semibold;
  }

  .var-decorator,
  .event-decorator {
    @apply text-xs;
  }
}

.cm-focused {
  outline: none !important;
}

.cm-gutters {
  background: transparent !important;
  border: unset !important;
}

.cm-diagnostic-error {
  @apply bg-white text-xs font-medium;
}

.cm-placeholder {
  @apply text-gray-600 #{!important};
}

ox-chat-template {
  $font: theme('fontFamily.sans');

  .cm-line {
    font-family: $font;
  }
}

// AUTOCOMPLETE

.cm-tooltip-autocomplete {
  @apply rounded-sm shadow-sm;

  background: white !important;
  border: 1px solid rgb(236 236 236 / var(--tw-border-opacity)) !important;
  font-family: inherit;

  li {
    @apply text-xs text-emerald-700;

    padding: 0.5rem !important;
    font-weight: 600 !important;

    &[aria-selected] {
      @apply bg-primary-500;
    }
  }

  .cm-completionIcon {
    @apply hidden;
  }
}

.cm-gutterElement {
  @apply flex items-center font-medium justify-center text-xs;
}
