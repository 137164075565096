.studio-editor {
  .ant-collapse-header {
    @apply h-10;

    align-items: center !important;
    padding-right: 0.75rem !important;
  }
}

.studio-header {
  @apply flex items-center gap-x-1;

  &__icon {
    @apply text-emerald-700;
  }

  &__title {
    @apply text-xs font-medium;
  }
}

.studio-content {
  @apply flex flex-col overflow-y-auto p-0;
}

.studio-expand-all {
  @apply flex items-center gap-x-1 rounded-full border border-gray-300 px-2 py-0.5 text-[13px] font-medium transition-colors hover:border-primary-600 hover:bg-primary-50 hover:text-primary-800 focus:outline-none dark:border-dark-700 dark:hover:bg-dark-700 dark:hover:text-dark-50;
}

.studio-navbar {
  @apply flex h-14 w-full shadow-border pl-3 pr-4 bg-white items-center border-b border-washed-gray-darker shrink-0;

  &__wrapper {
    @apply flex items-center gap-x-2;
  }

  &__back {
    @apply flex-center shrink-0 h-6 w-6 cursor-pointer rounded text-black hover:bg-washed-gray;
  }
}

.studio-configuration {
  @apply block h-full bg-white;

  &__item {
    @apply block border-b border-washed-gray;

    &:last-child {
      @apply border-0;
    }
  }
}

.studio-name {
  @apply flex items-center flex-auto;
}

.studio-name-control {
  @apply flex items-center flex-auto px-1.5 max-w-sm rounded gap-x-0.5 hover:bg-washed-gray h-7 cursor-pointer;

  &__title {
    @apply font-semibold grow-0 shrink basis-auto outline-none text-sm truncate;
  }

  &__icon {
    @apply text-gray-700;
  }
}

.studio-navigation {
  @apply flex items-center mx-2 gap-x-2;

  &__item {
    @apply transition-colors inline-flex justify-center shrink-0 h-7 items-center cursor-pointer px-3 rounded-full text-xs font-medium hover:bg-primary-50;

    &--active {
      @apply bg-primary-50 text-primary-900 font-medium hover:bg-primary-50;
    }

    &--disabled {
      @apply text-gray-400 pointer-events-none;
    }
  }
}

.studio-actions {
  @apply flex items-center justify-end gap-x-2 grow;
}

.studio-actions-menu {
  @apply flex items-center;

  &__item {
    @apply flex items-center px-2 py-1 h-7  rounded gap-x-1 text-xs font-medium cursor-pointer text-black focus:text-black hover:text-primary-800;
  }
}
