// *::-webkit-scrollbar {
//   @apply h-[0.6rem] w-[0.6rem];
// }

// *::-webkit-scrollbar-track {
//   @apply bg-white dark:bg-dark-700;
// }

// *::-webkit-scrollbar-thumb {
//   @apply rounded-2xl border-[0.1875rem] bg-gray-300 dark:border-dark-500;
// }

.log-context::-webkit-scrollbar-thumb {
  @apply dark:bg-dark-900;
}
