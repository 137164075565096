[json-value='array'] {
  @apply text-blue-500;
}

[json-value='object'] {
  @apply text-fuchsia-600;
}

[json-value='boolean'] {
  @apply text-green-500;
}

[json-value='datetime'] {
  @apply text-teal-500;
}

[json-value='integer'],
[json-value='number'] {
  @apply text-violet-500;
}

[json-value='string'] {
  @apply text-orange-500;
}
