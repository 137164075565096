.chart-metric-wrapper {
  @apply absolute h-full w-full;

  width: calc(100% - 3rem);
}

.chart-metric {
  @apply relative grid grid-cols-[7rem,1fr];

  &:not(:last-child) {
    @apply border-b border-gray-300;

    .chart-metric__heading {
      @apply flex items-center;
    }
  }

  &:last-child {
    @apply min-h-[120px];

    &::before {
      @apply absolute top-20 w-full border-b border-gray-300;

      content: '';
    }

    .chart-heading {
      @apply flex justify-center;
      height: calc(100% - 2.4rem);
    }

    .dashboard-empty {
      @apply h-full;
    }
  }

  &--loading {
    @apply grid-cols-1;

    &:last-child::before {
      @apply hidden;
    }
  }

  &__chart {
    @apply flex items-center;
  }

  .dashboard-empty {
    @apply h-full;
  }
}

// HEADING
.chart-heading {
  @apply flex flex-col justify-center;

  &__title {
    @apply text-[0.65rem] font-medium uppercase text-gray-700;
  }

  &__unit {
    @apply ml-0.5 text-[10px] font-semibold lowercase text-gray-600;
  }

  &__total {
    @apply text-xl font-medium;
  }
}

.chart-subheading {
  @apply flex gap-x-4;

  &__header {
    @apply flex flex-col justify-center pb-3;
  }
}

// TOOLTIP
.chart-tooltip-text {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, sans-serif;

  @apply font-normal;

  &--metric {
    @apply pb-2;
  }

  &--date {
    @apply mt-1;
  }
}
