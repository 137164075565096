// @ngxpert/hot-toast breaking change prerequisite for v5.0.0
@use 'node_modules/@ngxpert/hot-toast/src/styles/styles.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Codemirror

// Tippy
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/dist/svg-arrow.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/dist/backdrop.css';
@import 'tippy.js/animations/shift-away-subtle.css';
@import 'tippy.js/animations/scale-subtle.css';

// Custom
@import '../../../libs/ui/core/src/lib/styles';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600&display=swap');

@layer base {
  .flex-center {
    @apply flex items-center justify-center;
  }
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  font-family: 'Inter',
  ans-serif,
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Helvetica Neue,
  Arial,
  Noto Sans,
  sans-serif,
  Apple Color Emoji,
  Segoe UI Emoji,
  Segoe UI Symbol,
  Noto Color Emoji;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
  }
}

@supports (font-variation-settings: normal) {
  html {
    font-family: Inter var,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
  }
}

html,
body {
  font-family: Inter,
  sans-serif,
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Helvetica Neue,
  Arial,
  Noto Sans,
  sans-serif,
  Apple Color Emoji,
  Segoe UI Emoji,
  Segoe UI Symbol,
  Noto Color Emoji;
  @apply h-screen w-screen bg-ivory font-sans text-base  text-[#161616]  antialiased dark:bg-dark-900 dark:text-white;
}

button,
input,
a {
  // @apply outline-none transition-colors duration-75 ease-[cubic-bezier(.09,.76,.83,.67)];
}

label.required {
  @apply after:ml-0.5 after:text-red-500 after:content-['*'];
}

// Loader Animations
.loader-wrapper {
  @apply fixed inset-0 z-[2000] flex items-center justify-center bg-white dark:bg-dark-900;
  .bubble {
    @apply absolute h-20 w-20 rounded-[8rem]  bg-white shadow-2xl shadow-gray-300 dark:bg-dark-900 dark:shadow-dark-700 dark:shadow-dark-800;
    animation: bordertl 3s linear infinite,
    bordertr 4s linear infinite,
    borderbl 5.6s linear infinite,
    borderbr 3.3s linear infinite,
    rotate 3.6s linear infinite,
    hover 2s ease-in-out infinite;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-input-number-controls {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

code {
  @apply font-code text-sm font-medium;
}
