@keyframes bordertl {
  0%,
  100% {
    border-top-left-radius: 8rem;
  }
  25% {
    border-top-left-radius: 6rem;
  }
  50% {
    border-top-left-radius: 5rem;
  }
  75% {
    border-top-left-radius: 4rem;
  }
}

@keyframes bordertr {
  0%,
  100% {
    border-top-right-radius: 10rem;
  }
  25% {
    border-top-right-radius: 8.5rem;
  }
  50% {
    border-top-right-radius: 7rem;
  }
  75% {
    border-top-right-radius: 6rem;
  }
}

@keyframes borderbl {
  0%,
  100% {
    border-border-left-radius: 8rem;
  }
  25% {
    border-border-left-radius: 6rem;
  }
  50% {
    border-border-left-radius: 5rem;
  }
  75% {
    border-border-left-radius: 8rem;
  }
}

@keyframes borderbr {
  0%,
  100% {
    border-border-right-radius: 8rem;
  }
  25% {
    border-border-right-radius: 6rem;
  }
  50% {
    border-border-right-radius: 5rem;
  }
  75% {
    border-border-right-radius: 8rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

.cell-loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  position: relative;
  box-sizing: border-box;
  animation: shadowExpandX 1s linear infinite alternate;
  @apply bg-washed-gray;
}

@keyframes shadowExpandX {
  0% {
    box-shadow:
      0 0,
      0 0;
    @apply text-washed-gray/20;
  }
  100% {
    box-shadow:
      -14px 0,
      14px 0;
    @apply text-washed-gray/80;
  }
}



/// New logo
#animatedLetter {
  stroke-dasharray: 500; /* Adjust as needed */
  stroke-dashoffset: 500;
  animation: drawAndFill 5s infinite; /* Combined animation */
}

@keyframes drawAndFill {
  0%, 100% {
      stroke-dashoffset: 500;
      fill: none;
  }
  40% {
    stop-color: transparent;
      stroke-dashoffset: 100;
  }
  80%, 100% {
    stroke-dashoffset: 500;
      fill: none;
      stop-color: #24059D;
  }
}

@keyframes stopColorChange {
  0%, 40% {
      stop-color: transparent;
  }
  80%, 100% {
      stop-color: #24059D;
  }
}
