ox-input[type='text'],
ox-input[type='email'],
ox-input[type='url'],
ox-input[type='password'],
ox-input[type='number'],
ox-input[type='date'],
ox-input[type='datetime-local'],
ox-input[type='month'],
ox-input[type='search'],
ox-input[type='tel'],
ox-input[type='time'],
ox-input[type='week'],
ox-input[multiple] {
  @apply border-0 bg-transparent p-0;
}

.ox-input-group {
  @apply flex flex-col gap-y-1.5;
}

.ox-input-group label {
  @apply pl-0.5 text-xs;
}

ox-input.ng-invalid.ng-dirty .ox-input {
  @apply bg-red-100 text-red-800 placeholder:text-red-300 focus:border-red-300 focus:ring-red-100;
}

.ox-input {
  @apply block min-h-[34px] w-full rounded-md border border-[#F5F5F4] bg-[#F5F5F4] p-2 font-semibold transition-colors focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:text-black/50 dark:border-dark-600 dark:bg-dark-700 dark:text-dark-50 dark:placeholder:text-dark-300 dark:hover:border-primary-500 dark:focus:ring-1 dark:focus:ring-primary-500 sm:text-default;
}
