.nz-cdk-wrapper {
  @apply mx-auto flex min-h-max w-full max-w-8xl flex-col px-8;
}

.nz-cdk-create {
  @apply mb-6 mt-12 flex items-center justify-between;

  &__header {
    @apply flex gap-x-2 text-2xl font-semibold;
  }

  &__count {
    @apply text-black/50;
  }
}

.nz-cdk-separator {
  @apply mb-6 h-[1px] w-full border-b border-light-red;
}

.nz-cdk-form {
  @apply flex items-center justify-between;

  &__wrapper {
    @apply flex gap-x-4;
  }
}

// copying the nz-form color for icons inside the input
.ant-input-suffix ox-icon {
  color: rgb(0, 0, 0, 0.25);
}

.nz-cdk-select {
  @apply min-w-[200px] max-w-[200px];
}

.nz-cdk-sort {
  @apply min-w-[160px] max-w-[160px];
}

.nz-cdk-option {
  @apply flex h-full items-center gap-x-1;
}

.nz-cdk-table {
  @apply mb-12 mt-6 flow-root;

  &--cells-right {
    .cdk-header-cell:not(:first-child) {
      @apply text-right;
    }

    td:not(:first-child) .flex {
      justify-content: flex-end;
    }
  }

  &__wrapper {
    @apply -mx-4 -my-2 sm:-mx-6 lg:-mx-8;
  }

  &__body {
    @apply inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8;
  }

  &__content {
    @apply overflow-hidden rounded ring-1 ring-black ring-opacity-10;
  }

  table {
    @apply min-w-full divide-y divide-gray-300;
  }

  td {
    @apply h-[37px];
  }
}

.nz-cdk-placeholder {
  @apply w-full border-t border-light-red bg-white px-10 py-12;

  &__content {
    @apply flex flex-col items-center rounded border border-dashed border-light-red px-6 py-10 text-center text-xs font-semibold text-black/50;
  }
}

.nz-cdk-calculate {
  &:hover {
    @apply bg-primary-950/5;
  }
}
