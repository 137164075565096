%table-avatar-width {
  @apply w-12;
}

%table-name-width {
  @apply w-60;
}

// CDK TABLE
.cdk-table {
  @apply bg-white;

  .ant-skeleton-title {
    @apply mt-0;
  }
}

.cdk-row {
  @apply hover:cursor-pointer;
}

.cdk-table tbody {
  @apply divide-y divide-light-red;
}

.cdk-header-cell {
  @apply py-2.5 pl-4 pr-3 text-left text-xs font-semibold text-black/50 first:pl-4 first:pr-3 last:pl-3 last:pr-4 sm:pl-6;

  &:first-child {
    @extend %table-name-width;
  }

  &:not(:first-child) {
    @apply text-right;
  }
}

.cdk-row > .cdk-cell:first-child {
  @extend %table-name-width;

  span {
    @apply block max-w-[32rem] truncate;
  }
}

.cdk-row > .cdk-cell:not(:first-child) {
  @apply text-right;
}

.cdk-cell {
  @apply h-9 whitespace-nowrap px-3 py-1.5 text-xs font-medium text-black/70  first:pl-4 first:pr-3 last:pl-3 last:pr-4  group-hover:bg-primary-950/5 sm:pl-6;
}

.cdk-footer-cell {
  @apply group-hover:bg-white;
}

.cdk-cell.cdk-column-id {
  @apply font-medium text-black group-hover:text-primary-950;
}

.cdk-header-row .cdk-column-id {
  @apply text-black/50 group-hover:text-black/50;
}

.cdk-table-button-menu {
  @apply invisible bg-white group-hover:visible;

  &--active {
    visibility: visible !important;
  }
}

// AVATAR
.cdk-table-avatar-header {
  @extend %table-avatar-width;
}

.cdk-table-avatar-cell {
  @apply flex justify-end;
  @extend %table-avatar-width;

  nz-avatar {
    @apply flex-shrink-0 bg-primary-default;
  }
}

// LAYERED HEADERS TABLE
@mixin hover-effect() {
  &::after {
    @apply absolute left-0 top-[-5000px] z-0 h-[10000px] w-full bg-gray-100/70 transition-all;

    content: '';
  }

  &::before {
    @apply absolute left-0 top-0 z-10 block h-full w-full bg-gray-300 transition-all;

    content: '';
  }
}

.layered-table {
  @apply w-full cursor-default overflow-hidden rounded-md bg-white p-0 shadow-card ring-1 ring-black ring-opacity-5;

  &--loading {
    @apply relative h-[235px];
  }

  &__wrapper {
    @apply flex-center absolute inset-0;
  }

  table {
    @apply min-w-full border-separate border-spacing-0;
  }

  .layered-header {
    th {
      @apply relative z-20 overflow-hidden overflow-ellipsis whitespace-nowrap p-4;

      &:last-child {
        @apply border-r-0;
      }
    }

    &__main th {
      @apply bg-gray-50 py-3.5 pl-6 pr-3 text-center text-xs text-gray-900;

      &:first-child {
        @apply pl-6 pr-3;
      }

      &:last-child {
        @apply pl-3 pr-6;
      }

      &:not(:last-child) {
        @apply border-r border-gray-300;
      }

      &.active {
        @apply bg-gray-300;
      }
    }

    &__sub {
      @apply border-b-gray-200 bg-white;

      th {
        @apply px-3 py-3.5 text-right text-xs text-gray-900;

        &:first-child {
          @apply pl-6 pr-3 text-left;
        }

        &:last-child {
          @apply pl-3 pr-6;
        }

        &:not(:last-child) {
          @apply border-r border-gray-300;
        }
      }
    }
  }

  tbody {
    @apply bg-white;

    td {
      @apply relative;

      & > * {
        @apply relative z-20;
      }

      &:hover {
        @include hover-effect();
      }
    }

    td:first-child {
      @apply whitespace-nowrap;

      & > * {
        @apply flex h-full w-full py-4 pl-6 pr-3 text-xs font-medium text-gray-900;
      }
    }

    td:not(:first-child) {
      @apply whitespace-nowrap text-right tabular-nums;

      span {
        @apply block h-full w-full px-3 py-4 text-right text-xs;
      }
    }

    td:last-child {
      @apply pl-3 pr-6;
    }
  }

  td,
  th {
    @apply border-b border-gray-300;
  }

  .table-cell-divider {
    @apply border-r border-gray-300;
  }
}
