// PLAYGROUND NAVIGATION
.editor-navigation {
  @apply grid shrink-0 grid-cols-3 gap-x-2 bg-washed-gray-light px-4 pb-4 pt-2;
}

// PLAYGROUND BACK BUTTON
.editor-back {
  @apply flex items-center;

  &__btn {
    @apply flex-center flex h-6 w-6 cursor-pointer rounded text-gray-900 hover:bg-washed-gray dark:bg-dark-200 dark:text-dark-800 dark:hover:bg-dark-300;
  }
}

// PLAYGROUND TABS
.editor-tabs {
  @apply flex h-8 shrink-0 bg-washed-gray-light;

  &__wrapper {
    @apply flex h-full w-full justify-between px-4;
  }

  &__content {
    @apply flex h-full gap-x-1;
  }

  &__tab {
    @apply inline-flex h-8 items-center justify-center rounded-t-md bg-washed-gray px-3 py-1.5 text-[13px] font-semibold text-black/60  hover:bg-washed-gray-darker;

    &.active {
      @apply bg-white text-black;
    }

    &.highlighted {
      @apply bg-blue-100 text-primary-800;
    }
  }
}

// PLAYGROUND METRIC
.editor-metric {
  @apply flex cursor-default items-center gap-x-0.5 py-0.5 px-1 rounded-md;

  &:hover {
    @apply text-primary-700 hover:bg-primary-50;
  }

  ox-icon {
    @apply flex h-4 w-4 items-center justify-center rounded text-primary-700;
  }

  span {
    @apply text-xs font-medium;
  }
}

// PLAYGROUND PANEL
.editor-playground-panel {
  @apply relative flex h-full w-full flex-col border-washed-gray-darker bg-washed-gray-light;
}

// PLAYGROUND TOOLBAR
.editor-toolbar {
  @apply flex w-full items-center justify-between border-b border-washed-gray-darker bg-white px-4 py-2;
}
