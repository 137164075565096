.snippet {
  &-description {
    @apply text-xs text-zinc-600;
  }
  &-title {
    @apply mb-0 select-none text-xs font-medium;
  }
  &-block {
    @apply flex flex-col gap-y-3;
  }
}
