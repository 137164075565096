:root {
  --logs-header-row-height: 26px;
  --rules-template-columns: 64px 240px 100px 80px 140px;
  --deployments-template-columns: 64px 240px 80px 240px 80px 140px 100px 80px
    80px;
  --playground-template-columns: 64px 240px 240px 100px 80px 100px;
}

.logs-wrapper {
  @apply relative h-full w-full;
}

.logs-grid {
  @apply sticky top-0 z-20 inline-grid w-full text-xs [&>*]:px-2;

  grid-template-rows: var(--logs-header-row-height);
  height: var(--logs-header-row-height);

  &--rules {
    grid-template-columns: var(--rules-template-columns);
  }

  &--deployments {
    grid-template-columns: var(--deployments-template-columns);
  }

  &--playground {
    grid-template-columns: var(--playground-template-columns);
  }
}

.logs-column-headers {
  @apply z-40;
}

.logs-header {
  @apply flex bg-washed-gray-light h-full items-center border-r text-[10px] font-medium tracking-wide dark:border-dark-700 dark:text-dark-50;
}

.log-cell {
  @apply flex h-full flex-shrink-0 items-center border-r dark:border-dark-700 dark:text-dark-50;

  &.right {
    @apply justify-end;
  }
}

.log-action {
  @apply flex items-center gap-x-1 rounded-full border border-gray-300 px-2 py-0.5 text-[13px] font-medium transition-colors hover:border-primary-600 hover:bg-primary-50 hover:text-primary-800 focus:outline-none dark:border-dark-700 dark:hover:bg-dark-700 dark:hover:text-dark-50;
}
