.navbar {
  @apply sticky top-0 flex h-11 w-full justify-center border-b border-light-red bg-white;
}

.navbar-menu {
  @apply flex h-full items-center gap-x-2;

  &__item {
    @apply flex h-6  items-center gap-x-1 rounded-full px-3 text-sm font-medium text-black/50 transition-colors hover:text-black;

    &--active {
      @apply text-black;

      svg {
        @apply h-4 w-4 text-primary-500;
      }
    }
  }
}
