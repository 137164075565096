.drawer {
  @apply relative z-[51];

  &__button {
    @apply inline-flex h-7 w-7 items-center justify-center rounded border border-gray-300 bg-washed-gray text-default font-medium leading-4  transition-colors hover:bg-white focus:outline-none;

    &.active {
      @apply border-blue-200 bg-blue-100 text-primary-800;
    }
  }
}

.drawer-wrapper {
  @apply fixed right-0 z-50 p-4;

  &__content {
    @apply relative flex max-h-full flex-col rounded-xl bg-white px-1 py-2 shadow-soft;
  }

  &__spacer {
    @apply flex flex-1 overflow-y-auto;
  }
}

.drawer-header {
  @apply flex h-10 flex-shrink-0 items-center;

  &__content {
    @apply flex w-full items-center justify-between pl-4 pr-2;
  }

  &__title {
    @apply mb-0 text-default font-semibold;
  }

  &__close {
    @apply flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200;
  }
}

.drawer-body {
  @apply flex w-full flex-1 flex-col gap-y-0.5 overflow-y-auto px-4 py-3;

  &__loader {
    @apply absolute flex min-h-[76px] w-full items-center justify-center py-6;
  }
}

.drawer-body .ant-tabs-content-holder {
  @apply overflow-y-auto;
}

.drawer-tabs {
  @apply mb-6 flex gap-x-1 text-xs font-medium;

  &__tab {
    @apply cursor-pointer px-2 py-0.5;

    &--active {
      @apply rounded-2xl bg-primary-500 text-white;
    }
  }
}

.common-drawer {
  @apply p-2;

  box-shadow: none !important;

  .ant-drawer-content {
    @apply rounded-xl shadow-lg ring-1 ring-washed-gray-darker;
  }
}

.drawer-integration-creation {
  @apply p-4;
  box-shadow: none !important;
  width: 478px !important;
  right: 0;

  .ant-drawer-content {
    @apply rounded-xl shadow-lg ring-1 ring-washed-gray-darker;
  }
}

.drawer-log-detail {
  @apply w-full md:w-[720px];

  .ant-drawer-content {
    @apply shadow-card pointer-events-auto;
  }

  .ant-drawer-body {
    @apply p-0 overflow-hidden;
  }
}

.orq-drawer {
  box-shadow: none !important;
  @apply p-2 rounded;

  .ant-drawer-content {
    @apply shadow-card pointer-events-auto rounded;
  }
}
