.confirmation-dialog {
  @apply flex w-full flex-col bg-white p-8 rounded-lg;

  &__content {
    @apply leading-7;
  }

  &__title {
    @apply text-default font-medium text-gray-700 dark:text-dark-100;
  }

  &__description {
    @apply mt-4 text-default leading-6 dark:text-dark-50;

    span {
      @apply font-medium;
    }
  }

  &__truncate {
    @apply flex;

    span {
      @apply block max-w-[13rem] truncate pl-[0.2rem];
    }
  }

  &__action {
    @apply mt-6 flex;
  }
}

.ox-dialog-backdrop {
  @apply bg-black/50 transition-colors;
}

.ox-dialog {
  animation: dialog 150ms ease-in-out;
}

@keyframes dialog {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.compare-changes-dialog {
  width: calc(100vw - 4rem) !important;
  height: calc(100% - 1.5rem);
  @apply mt-0;

  .ant-modal-content {
    @apply h-full;
  }

  .ant-modal-body {
    height: calc(100% - 54px);
    @apply overflow-y-auto;
  }
}


.wizard-dialog {
  .ant-modal-body {
    height: calc(100% - 54px);
    @apply p-0;
  }
}
